<template>
  <div class="chessHand"
       style="padding-bottom: 20px">
    <div v-title
         data-title="电竞福利"></div>

    <!-- <van-nav-bar
      title="欢乐棋牌"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Esportswelfare.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 我的金额 -->
        <div class="rule-content"
             style="margin-bottom:20px">
          <div class="rule-content-title3"
               style="margin-top:0px"></div>
          <table>
            <tr>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background: linear-gradient(90deg, #3D73FA, #63B3FC);;color: #fff;width: 120px;">
                更新时间
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);;">
                {{ new Date().getFullYear() }}-{{ new Date().getMonth() > 10 ? new Date().getMonth() : '0' + new
                  Date().getMonth()
                }}-{{ new Date().getDate() > 0 ? new Date().getDate() : '0' + new Date().getDate() }}
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                {{ datalist.games.length > 1 ? '所有电竞' : datalist.games[0].name }}
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);;">
                {{ datalist.beated.beat }} 元
              </td>
            </tr>
          </table>
        </div>

        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">贝宝电竞</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">即时活动</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>

          <div style="font-size: 14px;margin:10px;color:var(--li-colortext);font-weight:400;    line-height: 22px;">
            活动期间，会员当日于贝宝电竞场馆投注满足总有效投注额要求，即可点击【立即申请】按钮申请最高1888元彩金！彩金仅需1倍流水即可取款。
          </div>
          <table>
            <thead style="
                          border-top-left-radius: 10px;
                          border-top-right-radius: 10px;
                        ">
              <tr style="
                        background: linear-gradient(90deg, #4581fa, #63b3fc)
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      ">
                <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                              border-top-left-radius: 10px;
                            ">
                  当日总有效投注额
                </th>
                <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                            ">
                  彩金金额
                </th>

                <th style="color: #fff; border-top-right-radius: 10px">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.giftList"
                  :key="index">
                <td>≥{{ item.beat }}</td>
                <td>{{ item.bouns }}</td>
                <td v-if="index == 0"
                    :rowspan="datalist.giftList.length">1倍</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即申请</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                仅限已完成实名认证的账号方可参与此活动。
              </li>
              <li>
                <span>2.</span>
                活动仅支持VIP1及以上的会员参与，同一手机号、姓名、银行卡号等信息的游戏账号，仅可参与一次。
              </li>
              <li>
                <span>3.</span>
                满足活动条件的会员需在当日的23:59:59的登录该页面并点击【立即申请】按钮进行申请活动，逾期视同放弃，不予补偿，申请成功后系统将于次日的48小时内自动派发至账户余额，数量较多可能存在一定延时请会员耐心等待，该活动结束48小时之后将不进行任何补发操作，彩金仅需1倍流水即可取款。
              </li>
              <li>
                <span>4.</span>
                仅支持贝宝电竞场馆的有效投注额，且仅对活动期间内下注并已结算且产生输赢的注单计算有效投注额。
              </li>
              <li>
                <span>5.</span>
                有效投注额以开赛时间为准，仅对已结算并产生输赢结果的注单进行计算，任何走盘、串关和取消的赛事将不计算在内，任何低于欧洲盘1.5的投注以及在同一赛事中同时投注对冲盘口的注单将不计算在内。
              </li>
              <li>
                <span>6.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回彩金及所产生的利润之权利。
              </li>
              <li>
                <span>7.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格；权利。
              </li>
              <li>
                <span>8.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: ''

    }
  },
  onShow() {
    console.log('tk1111', this.is_token)
  },

  mounted() {
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
  },

  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },

    getdata() {
      var _this = this
      var data = '?type=BeatSport&beat_act=1'
      getdata(data).then((res) => {
        this.datalist = res.message

      })
    },
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay() {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'BeatEsport',
                  beat_act: 1
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 申请
          uni.postMessage({
            data: {
              urlApply: 'BeatEsport'
            }
          })
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'BeatEsport',
          //       beat_act: 1
          //     },
          //   }
          // })

        }
      })
    },
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    > div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
